import moment from "moment-timezone" // Ensure moment-timezone is imported

export const getEventDate = (event) => {
  const currentDate = moment()
  const year = currentDate.year()

  // Calculate Labor Day (first Monday of September)
  const laborDay = moment(`${year}-09-01`).day(1) // Get the first Monday

  // If September 1st is not in the first week, adjust to the next Monday
  if (laborDay.date() > 7) {
    laborDay.add(1, "weeks")
  }

  // Calculate the event dates based on Labor Day
  const countryEdgeStartDate = laborDay
    .clone()
    .add(3, "days")
    .set({ hour: 17, minute: 30 }) // Friday at 5:30 PM
  const chilimaniaStartDate = laborDay
    .clone()
    .add(4, "days")
    .set({ hour: 11, minute: 0 }) // Saturday at 11:00 AM

  // Set the end of Chilimania to midnight
  const chilimaniaEndDate = chilimaniaStartDate.clone().endOf("day") // 23:59:59 of the event day

  // Determine if the current time is past the end of Chilimania
  const isAfterChilimania = currentDate.isAfter(chilimaniaEndDate)

  // If it's past the end of Chilimania, switch to next year's dates
  if (isAfterChilimania) {
    countryEdgeStartDate.add(1, "year")
    chilimaniaStartDate.add(1, "year")
  }

  // Return formatted date based on the event type
  return event === "countryEdge"
    ? countryEdgeStartDate.tz("America/Chicago").format("YYYY-MM-DD HH:mm:ss")
    : chilimaniaStartDate.tz("America/Chicago").format("YYYY-MM-DD HH:mm:ss")
}
